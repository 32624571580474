<div
  id="{{ controlName }}-input-container"
  [formGroup]="parentForm"
  [ngClass]="showErrors ? 'mb-2' : null">
  <mat-form-field class="text-input ng-invalid" [appearance]="appearance">
    <mat-label>{{ label ? label : placeholder }}</mat-label>
    <mat-chip-list
      #chipList
      [ngStyle]="{ 'margin-bottom': emails.length ? '10px' : undefined }">
      <mat-chip
        *ngFor="let email of emails"
        [removable]="true"
        (removed)="remove(email)">
        {{ email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
    <input
      #emailInput
      appear
      matInput
      [placeholder]="placeholder"
      [formControl]="emailControl"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      (keydown)="handleKeyDown($event)"
      [errorStateMatcher]="errorStateMatcher" />
    <input
      matInput
      id="{{ controlName }}-input"
      [formControlName]="controlName"
      type="text"
      [errorStateMatcher]="errorStateMatcher"
      hidden />
    <mat-hint
      align="start"
      [ngClass]="{
        'mat-error': multipleEmailControl.invalid && errorMessage.length
      }">
      {{ errorMessage }}
    </mat-hint>
    <mat-hint align="end">
      {{ totalEmailsLength + emailInput.value?.length || 0 }}/{{ maxLength }}
    </mat-hint>
  </mat-form-field>
</div>
