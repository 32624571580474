import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { PageLink } from '@app/core/models/page-link';
import { RouteHistoryService } from '@app/core/services/routing/route-history.service';
import { LegalDocumentModalComponent } from '@app/shared/components/legal-document-modal/legal-document-modal.component';
import {
  AuthSelectors,
  GlobalSelectors,
  SurveySelectors,
  UserDataSelectors,
} from '@app/store';
import {
  AuthActions,
  CaseActions,
  ContentfulActions,
  GlobalActions,
  SurveyActions,
  UserDataActions,
} from '@app/store/actions';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { FeedbackDialogComponent } from './shared/components/feeedback-dialog/feedback-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  // handles all component subscriptions
  subscriptions: Subscription = new Subscription();
  showLoadingVisual$: Observable<boolean> = this.store.select(
    GlobalSelectors.showLoadingVisual
  );
  isSideNavOpen = true;
  isShowSpinner = false;
  isUserLoggedIn = false;
  timer = null;
  pages: PageLink[] = [];

  feedDialogRef: MatDialogRef<FeedbackDialogComponent, any> = null;

  constructor(
    private store: Store<AppState>,
    public modal: MatDialog,
    private routeHistoryService: RouteHistoryService,
    public router: Router,
    private breakpointObserver: BreakpointObserver,
    private datePipe: DatePipe
  ) {
    this.checkIfUserAcceptedAndOpenFeedbackDialog();
    this.setNavState();
  }
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );
  ngOnInit(): void {
    // watch/save previous route
    this.routeHistoryService.watchRoutes();
    this.store.dispatch(AuthActions.checkAuth());
    // we need all of these available at application startup
    // should look to move somewhere else possibly
    this.initStoreValues();
    this.setNavItems();
    this.getOrCreateUser();
  }

  ngAfterViewInit(): void {
    this.store.dispatch(GlobalActions.hideError()); // clear any old error message modals on page load
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setNavState(): void {
    this.subscriptions.add(
      this.store.select(GlobalSelectors.isSideNavOpen).subscribe(isOpen => {
        this.isSideNavOpen = isOpen;
      })
    );
  }

  getOrCreateUser(): void {
    this.subscriptions.add(
      this.store.select(AuthSelectors.getUser).subscribe(b2cAccountInfo => {
        if (b2cAccountInfo) {
          this.isUserLoggedIn = true;
          // Get survey details
          this.store.dispatch(SurveyActions.getSurveySettings());
        } else {
          this.isUserLoggedIn = false;
        }
      })
    );
  }

  checkIfUserAcceptedAndOpenFeedbackDialog(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(e => e instanceof NavigationEnd),
          switchMap(() =>
            combineLatest([
              this.store
                .select(UserDataSelectors.getUserInfo)
                .pipe(
                  filter(
                    user => user?.hasAcceptedCustomerPrivacyAndTerms !== null
                  )
                ),
              this.store.select(SurveySelectors.getActiveSurveyInfo),
            ])
          )
        )
        .subscribe(([user, survey]) => {
          if (survey) {
            const surveyDetails = survey.loggedInSurveyDateRange;

            const surveyStartDate = new Date(
              this.datePipe.transform(
                surveyDetails?.startDate,
                'yyyy-MM-ddTHH:mm:ssZ'
              )
            );
            const surveyEndDate = new Date(
              this.datePipe.transform(
                surveyDetails?.endDate,
                'yyyy-MM-ddTHH:mm:ssZ'
              )
            );
            const currentUtcDate = new Date(new Date().toUTCString());
            const currentUtcDateString = currentUtcDate.toISOString();

            const surveyIds = Array.isArray(user.surveyIds)
              ? user.surveyIds
              : [];

            if (
              !surveyIds.includes(survey.id) &&
              currentUtcDateString >= surveyStartDate.toISOString() &&
              currentUtcDateString <= surveyEndDate.toISOString() &&
              this.router.url === '/dashboard'
            ) {
              this.openFeedbackDialogModal(surveyDetails?.surveyLink);
            }
          }

          // Handle user privacy acceptance
          if (!user.hasAcceptedCustomerPrivacyAndTerms) {
            this.modal.open(LegalDocumentModalComponent, {
              disableClose: true,
            });
          }
        })
    );
  }

  private openFeedbackDialogModal(formlink: string): void {
    this.feedDialogRef = this.modal.open(FeedbackDialogComponent, {
      data: { surveyLink: formlink },
      width: '90%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
    });

    const smallFBDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (size.matches) {
        this.feedDialogRef.updateSize(
          'calc(99vw - .3125rem)',
          'calc(99vh - .3125rem)'
        );
        this.feedDialogRef.updatePosition({
          bottom: '.3125rem',
          right: '.3125rem',
        });
      }
    });

    this.feedDialogRef.afterClosed().subscribe(() => {
      smallFBDialogSubscription.unsubscribe();
    });
  }

  initStoreValues(): void {
    // contentful data
    this.store.dispatch(ContentfulActions.getWhatsNew());
    this.store.dispatch(ContentfulActions.getTermsAndConditions());
    this.store.dispatch(ContentfulActions.getPrivacyPolicy());
    // dynamics case types
    this.store.dispatch(CaseActions.getCaseTypes());

    this.subscriptions.add(
      this.store
        .select(AuthSelectors.getUser)
        .pipe(filter(user => !!user))
        .subscribe(b2cAccountInfo => {
          this.store.dispatch(
            UserDataActions.userGetByAdObjectId({
              adObjectId: b2cAccountInfo.localAccountId,
            })
          );
          // save the user logged in time
          this.store.dispatch(
            UserDataActions.userSaveLoggedInTime({
              adObjectId: b2cAccountInfo.localAccountId,
            })
          );
        })
    );
  }

  onSideNavToggle() {
    this.store.dispatch(GlobalActions.toggleSideNav());
  }

  setNavItems(): void {
    this.pages = [
      {
        name: 'Dashboard',
        routerLink: '/dashboard',
        icon: 'assets/images/dashboard.svg',
        featureName: 'Dashboard',
      },
      {
        name: 'Sites',
        routerLink: '/organization/sites',
        icon: 'assets/images/sites.svg',
        featureName: 'Sites',
      },
      // {
      //   name: 'Operations',
      //   routerLink: '/operations/collection-status',
      //   icon: 'assets/images/operations.svg',
      //   featureName: 'Operations',
      // },
      {
        name: 'Support',
        routerLink: '/support-signed-in/support-ticket-list',
        icon: 'assets/images/headphones.svg',
        featureName: 'Support',
      },
      {
        name: 'Invoices',
        routerLink: '/invoices',
        icon: 'assets/images/invoices.svg',
        featureName: 'Invoices',
      },
      {
        name: 'Analytics',
        routerLink: '/analytics',
        icon: 'assets/images/analytics.svg',
        featureName: 'Analytics',
        pageSubLink: [
          {
            subItemName: 'General Report',
            featureName: 'GeneralReport',
            subItem: [
              {
                name: 'General',
                routerLink: '/analytics/reports/general',
                cyName: '',
                linkMinWidth: '',
                featureName: 'General',
              },
              {
                name: 'Finance',
                routerLink: '/analytics/reports/finance',
                cyName: '',
                linkMinWidth: '',
                featureName: 'Finance',
              },
              {
                name: 'Sustainability',
                routerLink: '/analytics/reports/sustainability',
                cyName: '',
                linkMinWidth: '',
                featureName: 'Sustainability',
              },
              {
                name: 'IoT',
                routerLink: '/analytics/reports/iot',
                cyName: '',
                linkMinWidth: '',
                featureName: 'IoT',
              },
            ],
          },
          {
            subItemName: 'Advance Report',
            featureName: 'AdvanceReport',
            subItem: [
              {
                name: 'Waste Stream Analysis',
                routerLink:
                  '/analytics/advanceReport/d6f6c8c9-1195-4b5f-a19a-c1c709ccb3b8',
                featureName: 'Waste Stream Analysis',
                id: 'd6f6c8c9-1195-4b5f-a19a-c1c709ccb3b8',
              },
              {
                name: 'Diversion Analysis',
                routerLink:
                  '/analytics/advanceReport/8111d7a7-15d2-4e4a-93a7-3525a8e67aa7',
                featureName: 'Diversion Analysis',
                id: '8111d7a7-15d2-4e4a-93a7-3525a8e67aa7',
              },
              {
                name: 'Roll Off Analysis',
                routerLink:
                  '/analytics/advanceReport/8c1bbc60-14f4-470a-a68f-1077ec7c977b',
                featureName: 'Roll Off Analysis',
                id: '8c1bbc60-14f4-470a-a68f-1077ec7c977b',
              },
            ],
          },
        ],
      },
      {
        name: 'Sensors',
        routerLink: '/sensors',
        icon: 'assets/images/sensors.svg',
        featureName: 'Sensors',
      },
      {
        name: 'Settings',
        routerLink: '/account/account-information',
        icon: 'assets/images/settings.svg',
        featureName: 'Settings',
      },
      {
        name: 'Documents',
        routerLink: '/documents',
        icon: 'assets/images/documents.svg',
        featureName: 'Documents',
      },
    ];
  }
}
