import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FilterdInvoiceRequestObject,
  FilteredInvoices,
  InvoiceDetail,
  InvoiceFile,
  Invoices,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import {
  CustomerInvoices,
  SiteHaulerContractDocuments,
} from '@app/core/models/site.model';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { AzureBaseService } from '@app/core/services/azure-functions/azure-base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends AzureBaseService {
  constructor(protected http: HttpClient) {
    super(http, '/func-portal-api-');
    this.httpOptions.headers = (this.httpOptions.headers as HttpHeaders).delete(
      'Ocp-Apim-Subscription-Key'
    );
  }

  getInvoices(requestBody: BillingAccountsReqObj): Observable<Invoices[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/customer-invoices`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as Invoices[]));
  }

  getFilteredInvoices(
    requestBody: FilterdInvoiceRequestObject
  ): Observable<FilteredInvoices> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/customer-invoices-dynamic`;

    return this.http
      .post<HttpResponse<any>>(requestUrl, requestBody, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as FilteredInvoices));
  }

  getInvoiceDetails(
    billingAccountId: string,
    invoiceId: string
  ): Observable<InvoiceDetail> {
    if (!billingAccountId) {
      return;
    }

    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/customer-invoices-details?billingAccountId=${billingAccountId}&invoiceId=${invoiceId}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as InvoiceDetail));
  }

  getInvoiceFile(
    region: 'usa1' | 'usa2',
    fileName: string,
    invoiceType: string
  ): Observable<InvoiceFile> {
    if (!fileName || !region) {
      return;
    }
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/fno-document/${fileName}/download?region=${region}&invoiceType=${invoiceType}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as InvoiceFile));
  }

  getInvoiceFileToEmbed(
    region: 'usa1' | 'usa2',
    fileName: string,
    invoiceType: string
  ): Observable<any> {
    if (!fileName || !region) {
      return;
    }
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/fno-document/${fileName}/download?region=${region}&invoiceType=${invoiceType}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => response.body as any));
  }

  getSiteHaulerContractDocuments(
    siteId
  ): Observable<SiteHaulerContractDocuments[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/sitehauleragreementdocuments/${siteId}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(
        map(
          response => JSON.parse(response.body) as SiteHaulerContractDocuments[]
        )
      );
  }

  getCustomerInvoicesBySite(
    customerId,
    customerNumber
  ): Observable<CustomerInvoices[]> {
    const requestUrl = `${this.azureBaseUrl}${this.functionUri}${this.azureEnv}/customer-invoices-by-site/accountid/${customerId}/customernumber/${customerNumber}`;

    return this.http
      .get<HttpResponse<any>>(requestUrl, this.httpOptions)
      .pipe(map(response => JSON.parse(response.body) as CustomerInvoices[]));
  }
}
