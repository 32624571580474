<div
  class="
  file-upload
  {{ showValidationError ? ' validation-error' : '' }}
  {{ files.length ? ' upload-disabled' : '' }}
">
  <div class="file-upload-label">
    {{ label }}<span *ngIf="isRequired"> *</span>
  </div>
  <div
    class="upload-container"
    appDragAndDrop
    (fileDropped)="onFileDropped($event)"
    (onFileWarningMessage)="showFileWarningMessage($event)">
    <!-- this.value=null allows the user to select the same file twice -->
    <input
      type="file"
      [accept]="allowedFileTypes.join(', ')"
      #fileDropRef
      id="fileDropRef"
      (change)="fileBrowseHandler($event)"
      onClick="this.value=null;" />
    <img src="assets/images/upload.svg" alt="Image Upload" />
    <div class="drag-and-drop-label">
      <label for="fileDropRef">Drag and drop file here</label>
      <div>or</div>
      <div class="label-button">Browse for file</div>
    </div>
  </div>
  <div class="upload-messages">
    <div class="error-message">
      {{ fileWarningMessage }}
    </div>
    <div class="supported-file-types" *ngIf="!fileWarningMessage">
      Supports {{ allowedFileTypes.join(', ') }}
    </div>
  </div>
</div>

<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <img src="assets/images/description.svg" alt="File" />
    <div class="info">
      <h4 class="name">
        {{ file?.name }}
      </h4>
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <app-progress-bar [progress]="file?.progress"></app-progress-bar>
    </div>
    <img
      class="delete"
      (click)="deleteFile(i)"
      src="assets/images/delete.svg"
      alt="Delete" />
  </div>
</div>
