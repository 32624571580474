import {
  FilterdInvoiceRequestObject,
  InvoiceDetail,
  InvoiceFile,
  Invoices,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { BillingAccountsReqObj } from '@app/core/models/UserDataModel';
import { createAction, props } from '@ngrx/store';

// Invoice list
export const getInvoices = createAction(
  '[Invoices] Get Invoices',
  props<{ requestBody: BillingAccountsReqObj }>()
);

export const getFilteredInvoices = createAction(
  '[Invoices] Get Filtered Invoices',
  props<{ requestBody: FilterdInvoiceRequestObject }>()
);

export const getInvoicesSuccess = createAction(
  '[Invoices] Get Invoices Success',
  props<{ invoices: Invoices[] }>()
);

export const getFilteredInvoicesSuccess = createAction(
  '[Invoices] Get Filtered Invoices Success',
  props<{
    customerInvoices: Invoices[];
    totalRecords: number;
  }>()
);

export const invoicesLoading = createAction(
  '[Invoices] Invoices Loading',
  props<{ loading: boolean }>()
);
export const setInvoicesListTableState = createAction(
  '[Site List] Set invoice table state',
  props<{
    filters: any;
    selectedAccounts: any[];
    paginator: { pageSize: number; pageIndex: number };
  }>()
);

export const getInvoicesFailure = createAction(
  '[Invoices] Get Invoices Failure',
  props<{ errorMsg: string }>()
);

// Invoice Details
export const getInvoiceDetails = createAction(
  '[Invoices] Get Invoice Details',
  props<{
    billingAccountId: string;
    invoiceId: string;
    siteId: string | null;
  }>()
);

export const getInvoiceDetailsSuccess = createAction(
  '[Invoices] Get Invoice Details Success',
  props<{ invoiceDetails: InvoiceDetail }>()
);

export const getInvoiceDetailsFailure = createAction(
  '[Invoices] Get Invoice Details Failure',
  props<{ errorMsg: string }>()
);

export const resetInvoiceDetails = createAction(
  '[Invoices] Reset Invoice Details'
);

// Invoice File
export const getInvoiceFile = createAction(
  '[Invoices] Get Invoice File',
  props<{ region: 'usa1' | 'usa2'; fileName: string; invoiceType: string }>()
);

export const getInvoiceFileSuccess = createAction(
  '[Invoices] Get Invoice File Success',
  props<{ invoiceFile: InvoiceFile }>()
);

export const getInvoiceFileFailure = createAction(
  '[Invoices] Get Invoice File Failure',
  props<{ errorMsg: string }>()
);
export const invoiceFileLoadingStart = createAction(
  '[Invoices] Invoice File Start Loading'
);

// Invoice File for Embed the
export const getInvoiceFileToEmbed = createAction(
  '[Invoices] Get Invoice File To Embed',
  props<{ region: 'usa1' | 'usa2'; fileName: string; invoiceType: string }>()
);

export const getInvoiceFileToEmbedSuccess = createAction(
  '[Invoices] Get Invoice File To Embed Success',
  props<{ invoiceFileToEmbed: string }>()
);

export const getInvoiceFileToEmbedFailure = createAction(
  '[Invoices] Get Invoice File To Embed Failure',
  props<{ errorMsg: string }>()
);

export const resetInvoiceFileToEmbed = createAction(
  '[Invoices] Reset Invoice File To Embed'
);

export const setPagination = createAction(
  '[Invoices] Set Invoice Page Size',
  props<{ pageIndex: number; pageSize: number }>()
);
