<div class="container-fluid sub-nav my-4" [ngClass]="styleClass">
  <div class="row page-title-row">
    <div
      class="col-12 col-md-auto page-title flex-grow-1"
      [ngClass]="hideSupportButtons ? 'col-md-12' : ''">
      <a
        [routerLink]="backButtonUrl"
        [queryParams]="backButtonQueryParams"
        *ngIf="showBackButton">
        <img
          class="crumb-arrow"
          alt="divider arrow"
          src="assets/images/arrow_back.svg" />
      </a>
      <div class="title">{{ title }}</div>
    </div>
    <div *ngIf="!hideSupportButtons" class="col-12 col-md-auto support-buttons">
      <div class="container m-0">
        <div class="row justify-content-md-end">
          <button
            (click)="rerouteOnClick('support-signed-in/service-request')"
            *appFeature="'RequestService'"
            class="col-12 col-md-auto">
            <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
            Request Additional Pickup
          </button>
          <button
            (click)="rerouteOnClick('support-signed-in/submit-a-ticket')"
            *appFeature="'RequestSupport'"
            class="col-12 col-md-auto mx-0 mx-md-2 my-2 my-md-0">
            <mat-icon aria-hidden="false" fontIcon="add"></mat-icon>
            New Support Request
          </button>
          <button
            (click)="rerouteOnClick('support-signed-in/service-change')"
            *appFeature="'ServiceChange'"
            class="col-12 col-md-auto">
            <mat-icon aria-hidden="false" fontIcon="update"></mat-icon>
            Service Change
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="subNavItems.length > 0" class="row mt-4">
    <div class="col-12">
      <ul class="tab-items {{ totalNavItems > 2 ? 'wrapped-nav' : '' }}">
        <ng-container *ngFor="let item of subNavItems">
          <li
            #subNavItem
            *appFeature="item.featureName"
            [class.active]="rla.isActive">
            <a
              #rla="routerLinkActive"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: false }"
              [routerLink]="item.routerLink"
              [attr.data-cy]="item.cyName">
              {{ item.name }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
