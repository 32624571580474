import { InvoicesState } from '@app/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectInvoicesSlice =
  createFeatureSelector<InvoicesState>('invoices');

export const getInvoices = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => {
    if (state.invoices) {
      return state.invoices;
    }
  }
);

export const getTotalRecords = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => {
    if (state.invoices) {
      return state.totalRecords;
    }
  }
);

export const getTableState = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.tableState
);

export const getInvoiceDetails = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.invoiceDetails
);

export const getInvoiceFile = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.invoiceFile
);
export const isInvoiceFileLoading = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.invoiceFileLoading
);
export const getInvoiceFileToEmbed = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.invoiceFileToEmbed
);
export const getInvoicesLoading = createSelector(
  selectInvoicesSlice,
  (state: InvoicesState) => state.loading
);
