import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  InvoiceDetail,
  InvoiceFile,
  Invoices,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { InvoicesService } from '@app/core/services/azure-functions/invoices.service';
import { InvoicesActions } from '@app/store/actions';

@Injectable()
export class InvoicesEffects {
  constructor(
    private actions$: Actions,
    private invoicesService: InvoicesService
  ) {}

  getInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.getInvoices),
      switchMap(({ requestBody }) =>
        this.invoicesService.getInvoices(requestBody).pipe(
          map((invoices: Invoices[]) =>
            InvoicesActions.getInvoicesSuccess({ invoices })
          ),
          catchError(err =>
            of(InvoicesActions.getInvoicesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  getFilteredInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.getFilteredInvoices),
      switchMap(({ requestBody }) =>
        this.invoicesService.getFilteredInvoices(requestBody).pipe(
          map(({ customerInvoices, totalRecords }) =>
            InvoicesActions.getFilteredInvoicesSuccess({
              customerInvoices,
              totalRecords,
            })
          ),
          catchError(err =>
            of(InvoicesActions.getInvoicesFailure({ errorMsg: err.message }))
          )
        )
      )
    )
  );

  // Invoice Details
  getInvoiceDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.getInvoiceDetails),
      switchMap(({ billingAccountId, invoiceId, siteId }) =>
        this.invoicesService
          .getInvoiceDetails(billingAccountId, invoiceId)
          .pipe(
            map((invoiceDetails: InvoiceDetail) =>
              InvoicesActions.getInvoiceDetailsSuccess({
                invoiceDetails: siteId
                  ? { siteId, ...invoiceDetails }
                  : invoiceDetails,
              })
            ),
            catchError(err =>
              of(
                InvoicesActions.getInvoiceDetailsFailure({
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );

  getInvoiceFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.getInvoiceFile),
      switchMap(({ region, fileName, invoiceType }) =>
        this.invoicesService.getInvoiceFile(region, fileName, invoiceType).pipe(
          map((InvoiceFile: InvoiceFile) =>
            InvoicesActions.getInvoiceFileSuccess({
              invoiceFile: InvoiceFile,
            })
          ),
          catchError(err =>
            of(
              InvoicesActions.getInvoiceFileFailure({
                errorMsg: err.message,
              })
            )
          )
        )
      )
    )
  );

  getInvoiceFileToEmbed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InvoicesActions.getInvoiceFileToEmbed),
      switchMap(({ region, fileName, invoiceType }) =>
        this.invoicesService
          .getInvoiceFileToEmbed(region, fileName, invoiceType)
          .pipe(
            map((invoiceFileToEmbed: string) =>
              InvoicesActions.getInvoiceFileToEmbedSuccess({
                invoiceFileToEmbed: invoiceFileToEmbed,
              })
            ),
            catchError(err =>
              of(
                InvoicesActions.getInvoiceFileToEmbedFailure({
                  errorMsg: err.message,
                })
              )
            )
          )
      )
    )
  );
}
