<ng-container *ngIf="(routerState$ | async)?.data as routerStateSnapshot">
  <app-top-sub-nav
    [title]="
      (invoiceDetails$ | async)?.invoiceNumber || routerStateSnapshot.title
    "
    [showBackButton]="routerStateSnapshot.showBackButton"
    [backButtonUrl]="
      (invoiceDetails$ | async)?.siteId
        ? '/organization/site/' +
          (invoiceDetails$ | async)?.billingAccount +
          '/' +
          (invoiceDetails$ | async)?.siteId +
          '/general'
        : routerStateSnapshot.backButtonUrl
    "></app-top-sub-nav>
  <router-outlet></router-outlet>
</ng-container>
