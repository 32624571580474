import {
  InvoiceDetail,
  InvoiceFile,
} from '@app/core/api-azure-functions/dataverse-functions/model/invoice';
import { InvoiceResponse } from '@app/core/api/model/invoiceResponse';
import { InvoicesActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';

export const invoicesFeatureKey = 'invoices';

export interface InvoicesState {
  invoices: InvoiceResponse[] | null;
  totalRecords: number;
  invoiceDetails: InvoiceDetail | null;
  invoiceFile: InvoiceFile | null;
  invoiceFileToEmbed: string | null;
  tableState: {
    filters: any | null;
    selectedAccounts: any[];
    paginator: { pageSize: number; pageIndex: number };
  };
  invoiceFileLoading: boolean;
  loading: boolean;
}

const initialState: InvoicesState = {
  invoices: null,
  totalRecords: 0,
  invoiceDetails: null,
  invoiceFile: null,
  invoiceFileToEmbed: null,
  tableState: {
    filters: null,
    selectedAccounts: [],
    paginator: { pageSize: 10, pageIndex: 0 },
  },
  invoiceFileLoading: true,
  loading: false,
};

export const invoicesReducer = createReducer(
  initialState,
  on(InvoicesActions.getInvoices, state => ({
    ...state,
    loading: true,
  })),
  on(InvoicesActions.getInvoicesSuccess, (state, { invoices }) => ({
    ...state,
    invoices,
    loading: false,
  })),
  on(
    InvoicesActions.getFilteredInvoicesSuccess,
    (state, { customerInvoices, totalRecords }) => ({
      ...state,
      invoices: customerInvoices,
      totalRecords,
      loading: false,
    })
  ),
  on(
    InvoicesActions.setInvoicesListTableState,
    (state, { filters, selectedAccounts, paginator }) => ({
      ...state,
      tableState: {
        filters,
        selectedAccounts,
        paginator,
      },
    })
  ),
  on(InvoicesActions.getInvoiceDetailsSuccess, (state, { invoiceDetails }) => ({
    ...state,
    invoiceDetails,
  })),
  on(InvoicesActions.resetInvoiceDetails, state => ({
    ...state,
    invoiceDetails: null,
  })),
  on(InvoicesActions.getInvoiceFileSuccess, (state, { invoiceFile }) => ({
    ...state,
    invoiceFile,
    invoiceFileLoading: false,
  })),
  on(InvoicesActions.invoicesLoading, (state, { loading }) => ({
    ...state,
    loading: loading,
  })),

  on(InvoicesActions.invoiceFileLoadingStart, state => ({
    ...state,
    invoiceFileLoading: true,
  })),
  on(
    InvoicesActions.getInvoiceFileToEmbedSuccess,
    (state, { invoiceFileToEmbed }) => ({
      ...state,
      invoiceFileToEmbed,
      invoiceFileLoading: false,
    })
  ),
  on(InvoicesActions.resetInvoiceFileToEmbed, state => ({
    ...state,
    invoiceFileToEmbed: null,
  }))
);
