import { ServiceSchedule } from '@app/core/api-azure-functions/dataverse-functions';
import { OnDemandProductModel } from '@app/core/models/on-demand-product.model';
import { OptionModel } from '@app/core/models/option.model';
import {
  ActiveSiteModel,
  CustomerInvoices,
  SiteDetailsModel,
  SiteHaulerContractDocuments,
  SiteModel,
} from '@app/core/models/site.model';
import { SiteActions } from '@app/store/actions';
import { createReducer, on } from '@ngrx/store';

export const sitesFeatureKey = 'sites';

export interface SitesState {
  currentSite: SiteDetailsModel | null;
  allSites: SiteModel[] | null;
  siteTableState: any;
  currentServiceSchedules: ServiceSchedule[];
  allAccountServiceSchedules: ServiceSchedule[];
  allAccountServiceSchedulesError: string | null;
  equipmentTypes: OptionModel[];
  equipmentSizes: OptionModel[];
  onDemandProducts: OnDemandProductModel[];
  sitesLoading: boolean;
  activeSitesLoading: boolean;
  sitesError: string | null;
  currentSiteLoading: boolean;
  currentServiceSchedulesLoading: boolean;
  allAccountServiceSchedulesLoading: boolean;
  allActiveSites: ActiveSiteModel[];
  siteHaulerContractDocuments: SiteHaulerContractDocuments[] | null;
  customerInvoices: CustomerInvoices[];
  customerInvoicesLoading: boolean;
  customerInvoicesError: string | null;
}

const initialState: SitesState = {
  currentSite: null,
  allSites: null,
  siteTableState: {
    searchText: '',
    pageNumber: null,
  },
  currentServiceSchedules: [],
  allAccountServiceSchedules: [],
  allAccountServiceSchedulesError: null,
  equipmentTypes: [],
  equipmentSizes: [],
  onDemandProducts: [],
  sitesLoading: true,
  activeSitesLoading: false,
  sitesError: null,
  currentSiteLoading: true,
  currentServiceSchedulesLoading: true,
  allAccountServiceSchedulesLoading: true,
  allActiveSites: [],
  siteHaulerContractDocuments: [],
  customerInvoices: null,
  customerInvoicesLoading: false,
  customerInvoicesError: null,
};

export const sitesReducer = createReducer(
  initialState,
  on(SiteActions.getSiteSuccess, (state, { currentSite }) => ({
    ...state,
    currentSiteLoading: false,
    currentSite,
  })),
  on(SiteActions.getSitesSuccess, (state, { allSites }) => ({
    ...state,
    sitesLoading: false,
    allSites,
  })),
  on(SiteActions.getSitesFailure, (state, { errorMsg }) => ({
    ...state,
    sitesLoading: false,
    sitesError: errorMsg,
  })),
  on(
    SiteActions.setSiteListTableState,
    (state, { searchText, pageNumber }) => ({
      ...state,
      siteTableState: {
        searchText,
        pageNumber,
      },
    })
  ),
  on(
    SiteActions.getServiceSchedulesSuccess,
    (state, { currentServiceSchedules }) => ({
      ...state,
      currentServiceSchedulesLoading: false,
      currentServiceSchedules,
    })
  ),
  on(
    SiteActions.getAllAccountServiceSchedulesSuccess,
    (state, { allAccountServiceSchedules }) => ({
      ...state,
      allAccountServiceSchedulesLoading: false,
      allAccountServiceSchedules,
    })
  ),
  on(
    SiteActions.getAllAccountServiceSchedulesFailure,
    (state, { errorMsg }) => ({
      ...state,
      allAccountServiceSchedulesLoading: false,
      allAccountServiceSchedulesError: errorMsg,
    })
  ),
  on(SiteActions.resetSiteDetails, state => ({
    ...state,
    currentSite: null,
    currentServiceSchedules: [],
  })),
  on(SiteActions.getEquipmentTypesSuccess, (state, { equipmentTypes }) => ({
    ...state,
    equipmentTypes,
  })),
  on(SiteActions.getEquipmentSizesSuccess, (state, { equipmentSizes }) => ({
    ...state,
    equipmentSizes,
  })),
  on(SiteActions.getOnDemandProductsSuccess, (state, { onDemandProducts }) => ({
    ...state,
    onDemandProducts,
  })),
  on(SiteActions.sitesStartLoading, state => ({
    ...state,
    sitesLoading: true,
  })),
  on(SiteActions.sitesStopLoading, state => ({
    ...state,
    sitesLoading: false,
  })),
  on(SiteActions.getServiceSchedulesFailure, (state, { errorMsg }) => ({
    ...state,
    currentServiceSchedulesLoading: false,
    sitesError: errorMsg,
  })),
  on(SiteActions.currentSiteStartLoading, state => ({
    ...state,
    currentSiteLoading: true,
  })),
  on(SiteActions.currentServiceSchedulesStartLoading, state => ({
    ...state,
    currentServiceSchedulesLoading: true,
  })),
  on(SiteActions.allAccountServiceSchedulesStartLoading, state => ({
    ...state,
    allAccountServiceSchedulesLoading: true,
  })),
  on(SiteActions.getActiveSitesSuccess, (state, { allActiveSites }) => ({
    ...state,
    activeSitesLoading: false,
    allActiveSites: allActiveSites,
  })),
  on(SiteActions.activeSitesloading, (state, { loading }) => ({
    ...state,
    activeSitesLoading: loading,
  })),
  on(
    SiteActions.getSiteHaulerContractDocumentsSuccess,
    (state, { siteHaulerContractDocuments }) => ({
      ...state,
      siteHaulerContractDocuments: siteHaulerContractDocuments,
    })
  ),
  // Invoices
  on(SiteActions.getCustomerInvoicesSuccess, (state, { customerInvoices }) => ({
    ...state,
    customerInvoicesLoading: false,
    customerInvoices,
  })),
  on(SiteActions.getCustomerInvoicesFailure, (state, { errorMsg }) => ({
    ...state,
    customerInvoicesLoading: false,
    customerInvoicesError: errorMsg,
  }))
);
